.skills-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.skills-container img {
  width: 60px;
  height: auto;
}

@media (max-width: 414px) {
  .skills-container {
    flex-direction: row;
  }
  .skills-container img {
    width: 30px;
  }
}
