.header-container {
  color: var(--white);
  font-size: 75px;
  height: calc(100vh - 2 * 40px);
  padding: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-container h1 {
  margin: 0;
  font-weight: 700;
}

.header-container p {
  font-size: 40px;
  color: white;
}

.languages {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
}

.languages > * {
  cursor: pointer;
}

@media (max-width: 768px) {
  .header-container {
    height: 100vh;
  }
  .header-container h1 {
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  .languages {
    right: 0;
    top: 50px;
    gap: 20px;
  }
}

@media (max-width: 414px) {
  .header-container h1 {
    font-size: 65px;
  }

  .header-container p {
    display: none;
  }

  .languages {
    top: 20px;
    right: 20px;
  }
}
